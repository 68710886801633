<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" route_type="catalogues" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Catalogue Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
            <v-col cols="12" md="12">
                <v-card>
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.image_file_name" :src="this.form.file_url + this.form.image_file_name"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Image</v-card-title>
                            <v-card-subtitle>{{ form.image_file_name}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.image_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="dlgVisibleImageMedia=true"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                            </v-card-actions>
                        </div>
                    </div>
                </v-card>
                    <div class="mt-3">
                    </div>
                
            </v-col>

                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="form.catalogue_id" disabled label="ID"></v-text-field>
                                <v-text-field outlined v-model="form.c_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.c_name" label="Name"></v-text-field>
                                <v-textarea outlined v-model="form.c_description" label="Description" rows="10"></v-textarea>                                
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>
                                <v-btn color="cbtnSave" dark @click="save">
                                    <v-icon left dark>save</v-icon>
                                        Save
                                </v-btn>                        

                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>
            </v-col>
            <!--image-->

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
import UploadFiles from "@/components/UploadFiles";
import ApCatalogueService from "@/services/ApCatalogueService"
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/catalogue',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            status_list:[],
            dlgVisibleImageMedia:false,           
            form:{login_id:"",start_date:"",end_date:"",file_url:"",image_file_name:""},
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'catalogues',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
      this.form.catalogue_id = this.$route.params.catalogue_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let title = " Update Catalogue"
        try{
            ApCatalogueService.view_catalogue(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
                this.status_list = res.data.statusList; 

                
            }).catch((e)=>{
                //console.log(e.response);
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        }
    },
    async save(){
        try{
            ApCatalogueService.update_catalogue(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Catalogue','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Catalogue',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }

          

    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        //console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'catalogues';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;
        //console.log(selectedImage);
        if(selectedImage.imageFileName){
            this.form.image_file_name = selectedImage.imageFileName
            this.form.file_url = selectedImage.fileUrl
        }        
    },
  }
}
</script>